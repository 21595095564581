:root {
    --font-family: "Inter", Arial, Helvetica, sans-serif;
    --purple: #373D5F;
    --golden: #C29C61;
    --success: #27AE60;
    --warning: #E2B93B;
    --error: #EB5757;
    --black: #000000;
    --black: #1D1D1D;
    --dim-black: #282828;
    --gray: #333333;
    --gray2: #4f4f4f;
    --gray3: #828282;
    --gray4: #BDBDBD;
    --gray5: #E0E0E0;
    --bodycolor: #313957;
}
.App {
    text-align: left;
}
body {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    letter-spacing: 0.01em;
    color: var(--bodycolor);
}

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
}

/* Prevent font size inflation */
html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
    margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
    line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2,
h3, h4 {
    text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
    color: currentColor;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
    font-family: inherit;
    font-size: inherit;
}


/* Anything that has been anchored to should have extra scroll margin */
:target {
    scroll-margin-block: 5ex;
}

input[type="checkbox"] {
    margin: 0;
    margin-top: -8px;
}

label:has(input[type="checkbox"], input[type="radio"]) {
    display: inline-block;
}

input[type="radio"] {
    margin-top: -4px;
}

/* HTML for checkbox: <label<input type="checkbox">Checkbox</label> */
input[type="checkbox"]:not(.switch) {
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid var(--gray3);
    vertical-align: middle;
    margin-right: 16px;
    position: relative;
    overflow: hidden;
}

input[type="checkbox"]:not(.switch):disabled {
    border: 2px solid var(--gray4);

}

input[type="checkbox"]:not(.switch):checked:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--golden) url(../images/tick.svg) no-repeat center;
}

input[type="checkbox"]:not(.switch):checked {
    border-color: var(--golden);
}

/* HTML for radio: <label for="radio"><input type="radio" name="test" id="radio">Radio</label> */
input[type="radio"] {
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid var(--gray3);
    vertical-align: middle;
    margin-right: 16px;
    position: relative;
    overflow: hidden;
    border-radius: 50px;
}

input[type="radio"]:disabled {
    border: 2px solid var(--gray4);

}

input[type="radio"]:checked:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: var(--golden);
    border-radius: 50px;
}

input[type="radio"]:checked {
    border-color: var(--golden);
}

/* HTML for switch: <label for="switch"><input type="checkbox" class="switch" id="switch">Switch</label> */
input[type="checkbox"].switch {
    appearance: none;
    width: 44px;
    height: 24px;
    border-radius: 4px;
    vertical-align: middle;
    margin-right: 8px;
    position: relative;
    overflow: hidden;
    background-color: var(--gray4);
    border-radius: 40px;
    transition: all 0.3s;
}

input[type="checkbox"].switch:disabled {
    border: 2px solid var(--gray4);

}

input[type="checkbox"].switch:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 100px;
    transition: all 0.3s;
}

input[type="checkbox"].switch:checked {
    background-color: var(--success);
}

input[type="checkbox"].switch:checked::before {
    left: 22px;
}

.po2rfms {
    background: #F9F9F9;
    min-height: 100vh;
    min-height: 100dvh;
    display: flex;
    padding: 30px 15px;
}

.po2rfms-login-form {
    margin: auto;
    width: 480px;
    border-radius: 12px;
    border: 1px solid #D4D7E3;
    min-height: 480px;
    padding: 48px;
    background: #FFFFFF;

}

.po2rfms-login-form h2 {
    text-align: center;
    margin-bottom: 37px;
    margin-top: 0;
    color: #0C1421;
}

.po2rfms-login-form p {
    text-align: center;
    margin: 0 auto 45px;
    max-width: 290px;
}

.po2rfms-fieldbox label {
    display: block;
    margin-bottom: 8px;
    float: left;
}

.po2rfms-fieldbox input {
    border: 1px solid #D4D7E3;
    border-radius: 8px;
    height: 48px;
    display: block;
    width: 100%;
    padding: 16px;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #0C1421;

}

button[type="submit"], .btn, button[type="button"]:not(.po2rfms-back-btn) {
    background: var(--golden);
    border: 2px solid var(--golden);
    color: #fff;
    padding: 16px 15px 16px 15px;
    border-radius: 12px;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: center;
    min-width: 275px;
    cursor: pointer;
    transition: all 0.3s;
}

button.btn, button[type="button"]:not(.po2rfms-back-btn) {
    min-width: 0;
}

.po2rfms-login-form button[type="submit"], button[type="button"]:not(.po2rfms-back-btn) {
    margin-top: 24px;
    width: 100%;
}

button[type="button"]:disabled,
button[type="submit"]:disabled {
    opacity: .4;
    cursor: no-drop;
	pointer-events: none;
}

button[type="submit"]:not(:disabled):hover, .btn:hover, button[type="button"]:not(.po2rfms-back-btn):hover {
    background: #fff;
    color: var(--golden);
}

button[type="submit"].blue, .btn.blue {
    background: var(--purple);
    border-color: var(--purple);
}

button[type="submit"]:not(:disabled).blue:hover, .btn.blue:hover {
    color: var(--purple);
}

.po2rfms-opt-fieldbox {
    display: flex;
    gap: 10px;
    text-align: center;
}

.po2rfms-opt-fieldbox input {
    text-align: center;
    padding: 5px;
}

.po2rfms-login-fieldbox input.invalid {
    border-color: var(--error);
}

.po2rfms-resend-code {
    width: max-content;
    display: block;
    margin: 10px auto 0;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-top: 20px;
    color: #0C1421;
    text-decoration: none;
    padding: 10px;
}

.po2rfms-resend-code:hover {
    text-decoration: underline;
}

.po2rfms-back-btn {
    position: absolute;
    top: 38px;
    left: 28px;
    width: 42px;
    height: 42px;
    border: 0;
    background: url(../images/arrow-back.svg) no-repeat center center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.po2rfms-opt-form {
    position: relative;
}
div.po2rfms-opt-form{
	text-align: center;
	min-height: 466px;
}
.po2rfms-mail-icon{
    width: 104px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 46px auto 20px;
}
div.po2rfms-opt-form h3{
    margin: 0 0 30px;
}

.po2rfms-back-btn:hover {
    opacity: .85;
}

.error-msg {
    display: none;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #DA0000;
    margin-top: 6px;
}
.error-message{
	
    color: #DA0000 !important;
    margin-top: 3px !important;
}
@media (max-width: 480px) {
    .po2rfms-login-form {
        padding: 30px;
    }

    button[type="submit"] {
        font-size: 16px;
    }

    .po2rfms-login-form h2 {
        margin-bottom: 17px;
    }

    .po2rfms-login-form p {
        margin-bottom: 25px;
    }

    .po2rfms-login-form {
        min-height: 400px;
    }
}

header {
    background: #373D5F;
    padding-inline: clamp(5rem, 9.4284rem + -9.2379vw, 2.5rem);
    position: sticky;
    z-index: 10;
}

.po2frm-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.po2frms-menu {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 30px;
}

.po2frms-menu > li {
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.po2frms-menu > li > a {
    padding: 19px 10px;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    position: relative;
    transition: all 0.3s ease;
}

.po2frms-menu > li > a:before {
    width: 100%;
    height: 4px;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    background: currentcolor;

}

.po2frms-menu > li a:is(:hover, .active) {
    color: #C29C61;
}

.po2frms-menu > li a:is(:hover, .active):before {
    opacity: 1;
}

.po2frms-actions {
    display: flex;
}

.pof2frms-dd {
    position: relative;
}

.pof2frms-dd > .targeted-element {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
    text-decoration: none;
    padding: 16px 10px;
    display: inline-flex;
    align-items: center;
    height: 100%;
	cursor: pointer;
}

.pof2frms-dd:has(ul) > .targeted-element>span:last-child{
	cursor: pointer;
	line-height: 24px;
	vertical-align: middle;
	display: inline-block;
	height: 24px;
	width: 24px;
	text-align: center;
}
.pof2frms-dd:has(ul) > .targeted-element>span:last-child:after {
    content: '';
    width: 24px;
    height: 24px;
    background: url(../images/dd-icon.svg) no-repeat center;
    display: inline-block;
    visibility: visible;
}

.pof2frms-dropdown.active {
    display: block; /* Show dropdown when 'show' class is added */
  }

.pof2frms-dd > .targeted-element .pof2frms-tags {
    padding: 3px 8px;
    background: #22A438;
    line-height: 18px;
    border-radius: 5px;
    margin-left: 8px;
    margin-right: 3px;
}

.pof2frms-tags.red-bg {
    background: red !important;
}

.pof2frms-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    padding: 0;
    list-style: none;
    background: #fff;
    margin: 0;
    padding-block: 8px;
    box-shadow: 0px 4px 14px 2px #00000026;
    min-width: 198px;
    border-radius: 6px;
    margin-top: -8px;
    display: none;
}

.pof2frms-dropdown > li > span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-align: left;
    padding: 13px 20px;
    display: block;
    text-decoration: none;
    opacity: .5;

}

.pof2frms-dropdown > li > a {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    padding: 13px 20px;
    display: block;
    text-decoration: none;
}

.user-avatar {
    margin-right: 8px;
}

.pof2frms-dropdown > li > a:is(:hover, .active) {
    background: #EBEBEB;
}

.pof2frms-container {
    max-width: 1170px;
    margin: 0 auto;
    width: calc(100% - 30px);
}

main {
    padding-block: clamp(3.75rem, 5.9642rem + -4.6189vw, 2.5rem);
}

table {
    width: 100%;
    border-collapse: collapse;
    border: 0;
    border-spacing: 0;
}

.po2frms-table {

    border: 1px solid #D4D7E3;
    border-radius: 6px;
}
table > thead > tr > th label {
    color: #000000;
}

table > thead > tr > th {
    text-align: left;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(0, 0, 0, .5);
    border-bottom: 1px solid #D4D7E3;
    background: #E0E0E0;
    padding: 15px;
}
table > tbody > tr > td {
    padding: 22px 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: left;

}

table > tbody > tr + tr > td {
    border-top: 1px solid #D4D7E3;

}

table > thead > tr > th input[type="checkbox"]:not(.switch) {
    margin: -3px 4px 0 10px;
}

table > tbody > tr > td input[type="checkbox"] {
    margin-top: -4px;
	padding: 0;
}

table > tbody > tr > td.actions a {
    display: inline-block;
    transition: all 0.3s ease;
}

/* table>tbody>tr>td.actions a:hover{
	opacity: .7;
} */
table > tbody > tr > td.actions a + a {
    margin-left: 32px;
}

.pof2frms-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;
    align-items: center;
}

.pof2frms-head h1 {
    margin-block: 0;
}

table > thead > tr > th:last-child,
table > tbody > tr > td:last-child {
    text-align: center;
}

table > thead > tr > th:first-child,
table > tbody > tr > td:first-child {
    padding-left: 30px;
}

.pof2frms-popup-wrapper {
    position: fixed;
    inset: 0;
    z-index: 12;
    background: rgba(0, 0, 0, .7);
    display: flex;
    padding: 20px;
	overflow: auto;
}
.pof2frms-popup-testcase .pof2frms-popup-container{
	width: 720px;
}
.pof2frms-popup-testcase table > thead > tr > th:last-child, .pof2frms-popup-testcase table > tbody > tr > td:last-child {
    text-align: left;
}
.pof2frms-popup-container {
    width: 433px;
    background: #fff;
    margin: auto;
    padding: 22px 28px;
    border-radius: 6px;
    position: relative;
}
.self-evaluation-dd{
position: relative;
}
text{
	font-size: 14px;
	line-height: 1;
	color: #0C1421;
	opacity: .6;
}
.pof2frms-builder{
	display: flex;
	gap: 9px;
	margin-bottom: 40px;
	flex-direction: column;
}
.pof2frms-builder>span{
	display: block;
	font-size: 14px;
	line-height: 14px;
	color: rgba(12, 20, 33, 1);
	opacity: .6;
}
.pof2frms-selfEvaluationScore .pof2frms-builder{
	width: 290px;
}
.self-evaluation-trigger{
    border: 1px solid rgba(212, 215, 227, 1);
    width: 100%;
    max-width: 290px;
	border-radius: 5px;
	padding: 16px;
	position: relative;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #000;
	cursor: pointer;
    padding-right: 50px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.self-evaluation-trigger::before{
	content: '';
	width: 30px;
	height: 30px;
	background: url(../images/arrow-icon.svg);
	position: absolute;
	top: 8px;
    right: 15px;
}
.self-evaluation-trigger.active::before{
	transform: rotate(180deg);
}
.self-evaluation-option{
	position: absolute;
	top: 100%;
	left: 0;
	width: 212px;
	box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	min-width: fit-content;
	background: #fff;
	margin-top: 7px;
	z-index: 1;
	padding-block: 7px;
}
.self-evaluation-option>label:has(input[type="checkbox"], input[type="radio"]){
	padding: 10px;
	display: flex;
	gap: 8px;
	align-items: center;
	align-content: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	cursor: pointer;
	color: rgba(12, 20, 33, 1);

}
.self-evaluation-option input[type="checkbox"]:not(.switch):checked:before{
	background-size: 11px auto;
}
.self-evaluation-option>label:has(input[type="checkbox"], input[type="radio"]):hover{
	background: rgba(235, 235, 235, 1);

}
.self-evaluation-option input[type="checkbox"]{
	margin: 0;
	width: 18px;
	height: 18px;
}
.pof2frms-popup-container > h3 {
    margin: 0;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin: 0 0 12px;
    color: #0C1421;

}

.pof2frms-popup-container > p {
    margin: 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #0C1421;

}

.pof2frms-popup-action {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.pof2frms-popup-action button {
    width: 140px;
    font-size: 16px;
}

.pof2frms-popup-close {
    position: absolute;
    top: 24px;
    right: 22px;
    width: 18px;
    height: 18px;
    transform: rotate(45deg);
    opacity: .6;
}

.pof2frms-popup-close::before {
    content: '';
    height: 18px;
    width: 2px;
    background: #0C1421;
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
}

.pof2frms-popup-close::after {
    content: '';
    height: 2px;
    width: 18px;
    background: #0C1421;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
}

.pof2frms-popup-close:hover {
    opacity: 1;
}

.pof2frms-popup-container input {
    border: 1px solid #D4D7E3;
    width: 100%;
    height: 48px;
    appearance: none;
    border-radius: 6px;
    padding: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-top: 10px;
}
.pof2frms-popup-container input:read-only{
	background: rgba(224, 224, 224, 1);
	border-color: rgba(224, 224, 224, 1);
}
.pof2frms-popup-container textarea{
    border: 1px solid #D4D7E3;
    width: 100%;
    height: 150px;
    appearance: none;
    border-radius: 6px;
    padding: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-top: 10px;
	display: block;

}
.pof2frms-popup-container select {
    border: 1px solid #D4D7E3;
    width: 100%;
    height: 48px;
    appearance: none;
    border-radius: 6px;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0.01em;
    text-align: left;
    margin-top: 10px;
    background: #fff url(../images/arrow-icon.svg) no-repeat right 15px center;
}

.pof2frms-popup-action button[type="button"]:not(.po2rfms-back-btn) {
    width: 140px;
	min-width: max-content;
}
.notes-section{
	margin-top: 40px;
}
.notes-section .pof2frms-perform-qa{
	justify-content: space-between;
	margin-bottom: 20px;
}
.notes-section textarea{
	width: 100%;
height: 114px;
background: rgba(248, 248, 248, 1);
padding: 24px;
border-radius: 5px;
}
.pof2frms-head .pof2frms-perform-qa {
	margin-bottom: 0;
    flex:1;
    justify-content: flex-end;
}
.pof2frms-perform-qa .po2rfms-login-form button[type="submit"], 
.pof2frms-perform-qa  button[type="button"]:not(.po2rfms-back-btn) {
	margin-top: 0;
	min-width: max-content;
	padding-inline: 30px;
}
.pof2frms-perform-qa  button[type="button"]:not(.po2rfms-back-btn).outline{
	background: #fff;
	border: 2px solid currentColor;
	color: var(--golden);
}
.pof2frms-perform-qa  button[type="button"]:not(.po2rfms-back-btn).outline:hover{
	background: var(--golden);
	color: #fff;
}
.pof2frms-perform-qa  button[type="button"]:disabled{
	opacity: .5;
	pointer-events: none;
}
.pof2frms-perform-qa {
	margin-bottom: 40px;
	justify-content: flex-start;
}
.pof2frms-perform-qa  button svg{
	vertical-align: middle;
	position: relative;
	top: -1px;
}
button.cancel-btn,
.pof2frms-popup-action button.pof2frms-cancel {
    --golden: #E0E0E0;
    color: #0C1421;
}

.pof2frms-popup-action button.pof2frms-deactivate {
    background: #E13E3E;
    color: #fff;
    border-color: #E13E3E;
}

.pof2frms-popup-action button.pof2frms-activate {
    background: var(--success);
    color: #fff;
    border-color: var(--success);
}

.pof2frms-popup-action button.pof2frms-activate:hover {
    background: #fff;
    color: var(--success);
    border-color: var(--success);
}

.pof2frms-popup-action button.pof2frms-deactivate:hover {
    background: #fff;
    color: #E13E3E;
}

.pof2frms-popup-action button.pof2frms-active:hover {
    background: #fff;
    color: var(--success);
}

.pof2frms-popup-action button.pof2frms-save:not(.po2rfms-back-btn) {
    min-width: max-content;
    width: 170px;
}
button.cancel-btn:hover,
.pof2frms-popup-action button.pof2frms-cancel:hover {
    --golden: #fff;
    color: #0C1421;
    border-color: #0C1421;
}

table > tbody > tr > td.po2frms-active:before,
table > tbody > tr > td.po2frms-inactive:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    border-radius: 50%;
    background: var(--success);
    margin-right: 6px;
}

table > tbody > tr > td.po2frms-inactive:before {
    background: var(--gray3);
}

table > tbody > tr > td.po2frms-active.qa:before {
    background: var(--warning);
}

table > tbody > tr > td.po2frms-active.error:before {
    background: var(--error);
}

.popup-overlay {
    position: fixed;
    inset: 0;
}

/* #system-popup{
	display: flex;
} */
.pof2frms-popup-center {
    text-align: center;
}

.pof2frms-popup-center .pof2frms-popup-action {
    justify-content: center;
}

#system-popup .pof2frms-popup-container {
    border-radius: 0;
    text-align: center;
    width: 620px;
    height: 398px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#system-popup .pof2frms-popup-container p {
    font-size: 16px;
    line-height: 19px;
}

#system-popup .pof2frms-popup-container p + p {
    margin-top: 20px;
}
.pof2frms-popup-file-choose{
    border: 0;
    border: 1px solid #d4d7e3;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    letter-spacing: .01em;
    line-height: 16px;
    margin-top: 10px;
    padding:5px 16px;
    text-align: left;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	display: flex;
	align-items: center;
}
.pof2frms-popup-file-choose input{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
}
.pof2frms-popup-select-file{
    padding: 10px 15px;
    display: inline-block;
        color: #fff;
    background-color: #c29c61;
    border-radius: 5px;
	margin-right: 5px;
}
.pof2frms-popup-file .pof2frms-popup-action button:not(.po2rfms-back-btn) {
    margin: 0;
    width: calc(50% - 5px);
}

.pof2frms-popup-file .pof2frms-popup-action button.full {
    width: 100%;
}

.pof2frms-popup-file .pof2frms-popup-action {
    flex-wrap: wrap;
    margin-top: 40px;
}
.tooltip{
	position: fixed;
}
.tooltip span{
	font-weight: 700;
}
.chartWrapper{
	position: relative;
	border-radius: 3px;
	background: rgba(212, 215, 227, .25);
}
.chartWrapper svg{
	width: 100%;
}
.domain{
	stroke-width: 0;
}
.pof2frms-note{
	background: rgba(220, 238, 223, 1);
	border-radius: 6px;
	padding: 9px 30px;
	margin-top: 20px;
}
.pof2frms-note p{
	margin: 0;
}

/* #compared-file{
	display: flex;
} */
.pof2frms-popup-wrapper .po2frms-table {
    margin-top: 20px;
}

/* .pof2frms-popup-wrapper table>thead>tr>th,
.pof2frms-popup-wrapper table>tbody>tr>td{
	padding: 15px 10px;
} */
.pof2frms-popup-wrapper table > tbody > tr > td:first-child input[type="checkbox"]:not(.switch) {
    margin-right: 5px;
    width: 18px;
    height: 18px;
}
.compare-builder-rule table>thead>tr>th,
.compare-builder-rule table>tbody>tr>td{
	width: 50%;
	text-align: left;
}
.compare-builder-rule table>tbody>tr+tr.compare-builder-tr>td{
	border-top: 2px solid var(--purple) !important;
}
.compare-builder-rule table>thead>tr>th:first-child,
.compare-builder-rule table>tbody>tr>td:first-child{
	width: 150px;
}
.compare-builder-rule table>tbody>tr:first-child>td{
	border-top: 1px solid var(--bodycolor);
}
table > tbody > tr > td:has(input[type="checkbox"]){
	width: 50px;
}
.pof2frms-popup-wrapper input[type="checkbox"].switch:disabled {
    border: 0px;
    opacity: .4;
}

.pof2frms-popup-compare .pof2frms-popup-container {
    width: 555px;
}

.pof2frms-popup-compared .pof2frms-popup-container .pof2frms-popup-action,
.pof2frms-popup-compare .pof2frms-popup-container .pof2frms-popup-action {
    justify-content: center;
}

.pof2frms-popup-compared .pof2frms-popup-container {
    width: 708px;
}

.pof2frms-popup-compared .pof2frms-popup-container :is(td, th) {
    text-align: left;
}

.po2frms-table p {
    margin: 0;
}

.po2frms-table p + p {
    margin-top: 10px;
}

.tag {
    padding: 6px 12px;
    min-width: 73px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.01em;
    background: #808080;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
}

.tag.active {
    background-color: var(--success);
}

[data-tooltip] {
    position: relative;
}
[data-tooltip]:not(td){
	display: inline-block;

}
[data-tooltip]:hover::before {
    content: attr(data-tooltip);
    background: #4F4F4F;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    padding: 8px 8px 8px;
    position: absolute;
    bottom: calc(100% + 4px);
    left: -5px;
    color: #fff;
    border-radius: 2px;
	
	width: fit-content;
	max-width: 200px;
}
.delete-row{
	cursor: pointer;
}
.red-text {
    color: red;
}
.delete-row:hover{
	opacity: .85;
}
[data-tooltip]:hover::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-top: 4px solid #4f4f4f;
    border-bottom: 0;
    content: '';
    position: absolute;
    bottom: 100%;
    left: 7px;
}

[data-tooltip]:last-child::before {
    left: -15px;
}

.po2frms-active.in {
    text-decoration: underline;
}

.po2frms-active.in::before {
    background: #828282;
}

table > tbody > tr.loading {
    pointer-events: none;
}

table > tbody > tr.loading > td .open-tooltip {
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: #C29C61;
	pointer-events: none;

}

table > tbody > tr.loading > td.actions {
    opacity: .5;
    pointer-events: none;
}

table > tbody > tr > td:has(.po2frms-tooltip) {
    position: relative;
}

table > tbody > tr > td:has(ins:hover) .po2frms-tooltip {
    visibility: visible;
    opacity: 1;
}

.po2frms-tooltip {
    position: absolute;
    left: 44px;
    width: max-content;
    bottom: 14px;
    max-width: 400px;
    background: #4F4F4F;
    padding: 8px;
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}

.po2frms-tooltip::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 5px solid #4f4f4f;
    border-left: 0;
    position: absolute;
    left: -5px;
    bottom: 16px;
}

.po2frms-tooltip h3 {
    color: #fff;
    margin: 0 0 10px;
    font-size: 12px;
    line-height: 14.52px;

}

.po2frms-tooltip ol {
    padding-left: 17px;
    margin: 0;
}

.po2frms-tooltip ol li {
    font-size: 12px;
    line-height: 14.52px;
    color: #fff;
}

.notification-container {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    max-width: 300px; /* Control the max width */
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack notifications vertically */
    gap: 10px; /* Add space between notifications */
    align-items: flex-start; /* Align notifications to the left */
}

/* Individual notification styling */
.notification {
    background-color: #28a745; /* Green color for success */
    color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    opacity: 0;
    animation: fadeIn 0.3s forwards; /* Fade-in animation */
}

.notify-wrapper {
    position: absolute;
    top: -30px;
    right: 0;
    z-index: 1;
    width: 309px;
}

.po2frm-header-inner .notify-wrapper {
    top: 100%;
    margin-top: 20px;

}

.po2frm-header-inner {
    position: relative;
}

.notification-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 16px;
    color: white;
}

/* Styling for 'View' links in the notification */
.notification a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
}

.pof2frms-scoring-notify {
    position: relative;
    border-radius: 6px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px 2px #00000026;
    padding: 24px 18px 24px 58px;
}
.pof2frms-scoring-notify {
    background-color: #f0f0f0; /* Light background */
    border: 1px solid #ccc; /* Border for visibility */
    margin-bottom: 5px; /* Space between notifications */
    border-radius: 5px;
}

.pof2frms-scoring-notify h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    color: #0C1421;
	padding-right: 25px;
}

.pof2frms-scoring-notify p {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #0C1421;

}

.pof2frms-head {
    position: relative;
	gap: 20px;
}
.pof2frms-head .cancel-btn{
	margin-left: auto;
}
.pof2frms-scoring-notify p a {
    font-weight: 700;
    color: var(--golden);
}

.pof2frms-scoring-notify p a:hover {
    text-decoration: none;
}

.pof2frms-scoring-notify::before {
    width: 26px;
    height: 26px;
    content: '';
    position: absolute;
	top: 20px;
    left: 18px;
    background: url(../images/tick-dd.svg) no-repeat center;
    background-size: contain;
}

.pof2frms-pagination {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 28px;
}

.pof2frms-pagination button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 15.75px;
    text-align: left;
    border: 0;
    display: inline-flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: none;
    cursor: pointer;
}

.pof2frms-pagination button:is(:hover, .active) {
    background: rgba(194, 156, 97, .15);
    color: rgba(194, 156, 97, 1);
    font-weight: 700;

}

.pof2frms-pagination button[disabled] {
    opacity: .5;
    pointer-events: none;
}

.po2frms-qa-status {
    width: 90px;
    text-align: right;
    margin-right: 38px;
    line-height: 22px;
    display: inline-block;
    vertical-align: top;
}

table > tbody > tr.po2frms-expandable-td > td {
    padding: 7px 0 15px;
    background: #FEFBF6;
    border-top: 0;
}

table > tbody > tr.po2frms-expandable-td > td > table {
    table-layout: flex;
}

table > tbody > tr.po2frms-expandable-td > td > table tr td {
    padding-block: 11px;
}

table > tbody > tr.po2frms-expandable-td > td > table tr td:first-child {
    width: 36.7%;
}
table > tbody > tr.po2frms-expandable-toggle > td > table tr td:first-child{
	padding-left: 90px;
	width: 51.3%;
}
table > tbody > tr.po2frms-expandable-td > td > table tr td:last-child {
    text-align: left;
}

table > tbody > tr.po2frms-expandable-td > td > table tr + tr td {
    border-top: 0;
}

table > tbody > tr > td.po2frms-expand {
    position: relative;
	padding-left: 30px;
}
table > tbody > tr > td :is(input[type="text"], input[type="number"]){
	border: 0;
	height: 65px;
	width: 100%;
	padding-inline: 10px;
	text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  resize: none;
}
table > tbody > tr > td :is(textarea[rows]){
	border: 0;
	height: 40px;
	width: 100%;
	padding-inline: 10px;
  resize: none;
}
.editable-text{
	position: relative;
}
table > tbody > tr > td.editable-text :is(textarea:not([rows])){
	border: 1px solid #000;
    height: 80px;
    left: 0;
    min-height: 0;
    padding: 16px;
    position: absolute;
    top: 0;
    width: 200%;
    z-index: 99;
    transform: translateX(-50%);
}
.delete-btn{
	min-width: 40px;
	width: 40px;
	padding-inline: 10px;
}
.delete-btn  [data-tooltip]{
    position: relative !important;
    top:0 !important;
    left:0 !important;
}
table > tbody > tr > td:is(.color, .qty,.units, .price, .final-price){
	position: relative;
	width: 90px;
}
table > tbody > tr > td:is(.color, .qty,.units, .price):has([data-tooltip]) :is(input[type="text"], input[type="number"]) {
    text-align: left;
	border: 0;
	padding-right: 40px;
}
table > tbody > tr > td:is(.final-price) :is(input[type="text"], input[type="number"]){
	background: rgba(243, 243, 243, 1);
}
table > tbody > tr > td:has(input[type="text"], input[type="number"], textarea[rows],.textarea) [data-tooltip],


table > tbody > tr > td:is(.color, .qty,.units, .price,.final-price) [data-tooltip]{
	position: absolute;
	top: 50%;
	margin-top: -9px;
	right: 20px;
	z-index: 1;
}
/* Chrome, Safari, Edge, Opera */
table > tbody > tr > td input::-webkit-outer-spin-button,
table > tbody > tr > td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
table > tbody > tr > td input[type=number] {
  -moz-appearance: textfield;
}
table > thead.po2rfms-table > tr > th{
	background: rgba(254, 251, 246, 1);

}
table > thead.po2rfms-table > tr > th:last-child{
	border-right: 0;
}
table > tbody > tr > td.delete-btn{
	border-right: 0;
}
.table-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
}
 table > tbody > tr > td:first-child.notearea{
    padding: 18px;
     background: rgba(243, 243, 243, 1);
}
table > tbody > tr > td:first-child.notearea textarea{
    width: 100%;
height: 70px;
border-radius: 6px;
border: 1px solid rgba(212, 215, 227, 1);
padding: 18px;
    resize: none;
}
table > tbody > tr > td:has(input[type="text"], input[type="number"], textarea[rows],.textarea){
	padding: 0;
	border-right: 1px solid #D4D7E3;
    position: relative;
}
table > tbody > tr > td:has(input[type="text"], input[type="number"], textarea[rows],.textarea) .textarea{
	padding: 10px;
    min-height: 66px;
}
table > tbody > tr > td:has(input[type="text"], input[type="number"], textarea[rows],.textarea).units{
	width: 50px;
}
table > tbody > tr > td:has(input[type="text"], input[type="number"], textarea[rows],.textarea)[colspan="4"]{
	border-right: 0;
}
.po2frms-table td.no-result-founds{
	padding: 20px;
	font-weight: 700;
	font-size: 20px;
	line-height: 1.25;
}
table > tbody > tr > td[colspan="4"]{
	padding: 0;
}
.insertRow, .addNotes{
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	gap: 10px;
	cursor: pointer;
}
.addNotes.disable{
	opacity: .4;
	pointer-events: none;
}
:is(.insertRow, .addNotes) svg{
	color: var(--golden);
}
.po2frms-table td[colspan="6"]{
	padding: 15px !important;
}
.insertRow:hover, .addNotes:hover{
	transition: all 0.3s ease;
	opacity: .85;
}
/* .addNotes{
	margin-left: 10px;
} */
 .po2frms-table-action{
	display: flex;
	gap: 10px;
	justify-content: center;
	flex-wrap: wrap;
 }
table > tbody > tr.qa-header > td{
    padding: 13px 15px;
}
.batch-box{
    padding: 12px;
    display: inline-block;
    border: 1px solid transparent;
}
.qa-header:has(.expanded) .batch-box{
	border: 1px solid rgba(0, 0, 0, .2);
}
table > tbody > tr.qa-header > td:first-child{
    padding-left: 30px;
}
/* table > tbody > tr.po2frms-expandable-td {
    display: none;
} */

table > tbody > tr > td.po2frms-expand::before {
    position: absolute;
    top: 29px;
    left: 17px;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 5px solid #6d7270;
    border-right: 0;
}
table > tbody > tr > td input[type="radio"]{
	margin: 0;
}
table > tbody > tr > td:has(input[type="radio"]){
	width: 20px;
}
.po2frms-table table tr:has(.expanded) {
    background: rgba(232, 178, 94, .2);
}
.open-tooltip{
	cursor: pointer;
	color: inherit;
}
.po2frms-table table tr:has(.expanded) td.po2frms-expand::before {
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-top: 5px solid #6d7270;
    border-bottom: 0;
}

.po2frms-expand {
    cursor: pointer;
}

.thead {
    opacity: .6;
}
.tooltip{
	font-size: 12px;
	line-height: 1;
	background: #FFFFFF;
	padding: 12px 12px 8px;
	box-shadow: 0px 2px 4px 0px #00000040;
	border-radius: 6px;
}

.tooltip::before{
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	border-top: 9px solid #FFFFFF;
	filter: drop-shadow(0px 2px 2px #00000040);
	border-bottom: 0;
	position: absolute;
    top: 100%;
    left: 20px;
}
.tooltip button{
	border: 0;
	background: none;
	text-decoration: underline;
	cursor: pointer;
	font-weight: 700;
}
.tooltip button:hover{
	text-decoration: none;
}
.tooltip strong{
	opacity: .6;
	text-transform: uppercase;
	font-weight: 400;
	display: block;
	margin-bottom: 8px;
}
.pof2frms-statuses{
    display: flex;
    margin-bottom: 20px;
    gap: 18px;
}
.statusbox{
    display: flex;
    padding: 21px 30px;
    background: rgb(212 215 227/ 25%);
    border-radius: 6px;
    justify-content: space-between;
    width: 50%;
    max-width: calc(50% - 8px);
	gap: 10px;
}
.pof2frms-statuses h3{
    font-size: 26px;
    font-weight: 600;
    line-height: 31.47px;
    text-align: left;
    margin: 0 0 7px;
}
.pof2frms-selfEvaluationScore{
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
	align-items: center;
}
.pof2frms-selfEvaluationScore h2{
	margin: 0;
}
.pof2frms-selfEvaluationScore .pof2frms-builder{
	margin-bottom: 0;
}
.pof2frms-statuses h3 small{
	font-size: 20px;
}
.status-icon{
    background: #7CAC4D;
    width: 76px;
    height: 76px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
	min-width: 76px;
}
.status-icon.blue{
    background: rgba(83, 173, 202, 1);
}
.pof2frms-statuses p{
    font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
text-align: left;
    color: rgba(16, 24, 53, .5);

}

.ship-batch-row{
    display: flex;
    gap: 15px;
}
.po2rfms-info-text{
	margin-bottom: 10px;
}
.ship-batch-column{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 9px;
}
.ship-batch-column a{
	color: var(--golden);
}
.ship-batch-column a:hover{
	text-decoration: none;
}
.ship-batch-column div{
    width: 100%;
height: 80px;
border-radius: 6px;
background: rgba(243, 243, 243, 1);
    border-color: rgba(243, 243, 243, 1);
    resize: none;
    padding: 16px;
	font-size: 14px;
	line-height: 1;
}
.ship-batch-column span{
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(12, 20, 33, .6);
}
.pof2frms-loading-bar{
    width: 321px;
height: 10px;
    background: rgba(0,0,0, .1);
    border-radius: 10px;
    margin-inline: auto;
    position: relative;
    overflow: hidden;
}
.pof2frms-loading-bar:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--golden);
    width: var(--processing);
    border-radius: 10px;
}
.pof2frms-popup-container:has(.pof2frms-loading-bar) {
    min-height: 210px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.pof2frms-popup-container .pof2frms-loading-bar{
	margin-top: 30px;
}
.pof2frms-popup-form{
    width: 645px;
}
.pof2frms-popup-form form{
    display: flex;
    flex-wrap: wrap;
    gap: 20px 16px;
}

.po2rfms-input-box{
    width: calc(50% - 8px);
}

.po2rfms-input-box label{
    font-size: 14px;
    line-height: 1;
    opacity: .6;
    color: rgba(12, 20, 33, 1);
}
.pof2frms-popup-form .pof2frms-popup-action{
    width: 100%;
    align-items: flex-end;
    justify-content: center;
}

.pof2frms-popup-form .pof2frms-popup-action button{
    margin: 0 !important;
    max-width: 200px !important;
    width: 200px !important;
    min-width: 0;
}
.po2frms-table-action:not(.po2frms-show-action){
	visibility: hidden;
}
.pof2frms-popup-file-choose>div{
	width: calc(100% - 130px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.po2frms-batch-name{
	cursor: pointer;
}
.po2frms-batch-name:after{
    width: 0;
    height: 0;
    border-style: solid;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 5px solid #6d727a;
    border-bottom: 0;
    display: inline-block;
    content: '';
	margin-left: 5px;
}
.po2frms-batch-name.active:after{
	transform: rotate(180deg);
}
.po2frms-batch-error:after{
    border-top-color: red;
}
.pof2frms-container:has(.ship-batch-row){
    max-width: 1260px;
}
table > tbody > tr > td:is(.qty, .units){
    width: 100px;
}
table > tbody > tr > td:is(.price){
    width: 110px;
}
table > tbody > tr > td:is(.final-price){
    width: 100px;
}
table > tbody > tr > td:is(.color){
    width: 160px;
}
table > tbody > tr > td:has(input[type="text"], input[type="number"], textarea[rows],.textarea).units {
    width: 100px;
}

table > tbody > tr > td.editable-text{
    width: 320px;
}
.pof2frms-popup-search .pof2frms-popup-container{
    width: 930px;
	overflow: hidden;
    padding: 0;
}
.pof2frms-popup-search .pof2frms-popup-action{
	justify-content: flex-start;
}
.pof2frms-popup-search .pof2frms-popup-action button{
	margin-top: 20px;
}
.pof2frms-popup-search .pof2frms-popup-form{
	display: flex;
	align-items: center;
	gap: 10px 16px;
	flex-wrap: wrap;
	width: 100%;
}
.pof2frms-popup-form input{
    margin: 0;
    max-width: 100%;
}
.pof2frms-popup-form input[type="search"]{
    width: calc(100% - 100px)
}

.pof2frms-popup-form button[type="submit"]{
    min-width: 130px;
}

/* .pof2frms-popup-container .po2rfms-anyProductSelect{
    background-image: none;
    height: 270px;
	line-height: 40px;
	padding: 0;
	font-size: 14px;
	line-height: 1;
    overflow: auto;
    border: 1px solid #D4D7E3;
    border-radius: 5px;
    margin-top: 10px;
}
.pof2frms-popup-container .po2rfms-anyProductSelect .po2rfms-label {
	padding: 13px 10px;
    border-bottom: 1px solid #D4D7E3;
    display: block;
}
.pof2frms-popup-container .po2rfms-anyProductSelect .po2rfms-label.po2rfms-selected {
	background-color: var(--golden);
	color: white; 
  } */
  
.pof2frms-popup-column-left{
    width: calc(100% - 320px);
	padding: 28px 20px;
}

.pof2frms-popup-column-right{
    width: 320px;
	padding: 28px 20px;
	background: #f3f3f3;
    border-left: 1px solid #D4D7E3;
}
.pof2frms-popup-row{
    display: flex;
}
.pof2frms-popup-column-right h3{
    margin-top: 0;
    margin-bottom: 10px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 34px;
/* identical to box height, or 170% */

color: #0C1421;
}
.pof2frms-popup-column-right input,
.pof2frms-popup-column-right input:read-only{
    margin: 0 0 20px;
	background: #fff;
	height: 44px;
	padding: 10px 20px;
}
.pof2frms-popup-column-right input:last-child{
	margin-bottom: 0;
}
.pof2frms-popup-column-right label{
	margin-bottom: 10px;

font-weight: 400;
font-size: 14px;
line-height: 100%;

/* Primary text */
color: #0C1421;

opacity: 0.8;
display: block;
}
.pof2frms-popup-search .pof2frms-popup-container {
    position: relative;

}
.po2rfms-loader-overlay{
    position: absolute;
    inset: 0;
    background: #ffffff;
    opacity: 0.7;
    z-index: 6;
}
.po2rfms-loader {
    color: var(--golden);
    font-size: 45px;
    text-indent: -9999em;
    overflow: hidden;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -23px;
    z-index: 10;
    margin-left: -23px;
    transform: translateZ(0);
    animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
  }
  
  @keyframes mltShdSpin {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 
      0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, 
      -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, 
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
       -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, 
       -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
       -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, 
       -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 
      0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  
  @keyframes round {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
   
.pof2frms-popup-search .pof2frms-popup-container>h3{
    background: #FAF0DF;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;
/* identical to box height */

color: #0C1421;
    text-align: center;
    padding: 20px;
	margin-bottom: 0;
}
.pof2frms-popup-form{
    gap: 10px 16px;
}
.pof2frms-popup-form>label{
    width: 100%;
    font-weight: 500;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    color: #0C1421;

}
.pof2frms-popup-search .pof2frms-popup-form button{
    margin-left: 0;
    min-width: 140px;
	padding-block: 9px;
	height: 44px;
	border-radius: 6px;
}
.pof2frms-popup-search .pof2frms-popup-action button[type="button"]:not(.po2rfms-back-btn){
	width: 180px;
    height: 44px;
    padding-block: 9px;
    border-radius: 6px;
}
.pof2frms-popup-search input[type=search]{
    width: calc(100% - 156px);
	padding-block: 10px;
	height: 44px;
}
@media (max-width: 1370px) {
    header {
        padding-inline: 15px;
    }

    .po2frm-header-inner {
        max-width: 1170px;
        margin: auto;
    }
}

@media (max-width: 1024px) {
    table > thead > tr > th:first-child, table > tbody > tr > td:first-child {
        padding-left: 15px;
    }

    table > tbody > tr > td {
        padding: 22px 10px;
    }

    table > tbody > tr > td.actions a + a {
        margin-left: 12px;
    }

    .po2frms-qa-status {
        margin-right: 12px;
    }

    input[type="checkbox"].switch {
        margin-right: 5px;
    }

    .po2frms-menu {
        gap: 15pxd;
    }

    table > tbody > tr > td.po2frms-expand {
        width: 200px;
        line-height: 1.2;
    }

    table > tbody > tr > td.po2frms-expand::before {
        top: 26px;
        left: 7px;

    }

    table > tbody > tr.po2frms-expandable-td > td > table tr td:first-child {
        width: 200px;
    }
	table > tbody > tr.po2frms-expandable-toggle > td > table tr td:first-child{
		width: 250px;
        padding-left: 60px;
	}

    table > tbody > tr.po2frms-expandable-td > td {
        padding-left: 0;
    }
	table > tbody > tr > td.po2frms-expand, table > tbody > tr.qa-header > td:first-child{
		padding-left: 15px;
	}
	table > tbody > tr > td :is(input[type="text"], input[type="number"]){
		padding-left: 15px;
	}
	table > tbody > tr.qa-header > td {
		padding: 10px 15px;
	}
	.pof2frms-head:has(.pof2frms-perform-qa){
		flex-direction: column;
		align-items: flex-start;
	}
}